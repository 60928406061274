.one-article {
  width: 1000px;
  min-height: 500px;
}

.one-article-header {
  width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.one-article-header-background {

  margin-top: 25px;

  width: 800px;
  height: 450px;
}


.one-article-header-title {
   color: black;
   font-size: 40px;
   font-weight: bold;

   width: 1000px;
   padding-bottom: 5px;
   padding-top: 30px;

   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.hashtag-upper-article {

    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;

}

.one-article-content-container {
  padding: 30px;
  padding-left: 100px;
  padding-right: 100px;
}

.one-article-content-upper-abzac {
  padding: 30px;
  padding-bottom: 0px;

  padding-left: 100px;
  padding-right: 100px;

  color: black;
  font-size: 20px;
  font-weight: bold;

  width: 80%;

}

.one-article-header-lower {

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 80%;

  color: rgb(53, 53, 53);


  border-bottom: 7px solid black;

}

.one-article-header-hashtegs {
  margin-left: 40px;
  margin-bottom: 30px;

  padding-left: 60px;
  padding-right: 100px;

}


/* МОБИЛЬНАЯ ВЕРСТКА */

@media screen and (max-width:900px) {

  .one-article {
    width: 90vw;
    min-height: 500px;
  }
  
  .one-article-header {
    width: 90vw;
  }
  
  .one-article-header-background {
  
    margin-top: 15px;
  
    width: 70vw;
    height: 190px;
  }
  
  
  .one-article-header-title {
     font-size: 20px;
  
     width: 90vw;
     padding-bottom: 15px;
     padding-top: 20px;
  }
  
  .hashtag-upper-article {
  
      margin-left: 3px;
      margin-right: 2px;
  
  }
  
  .one-article-content-container {
    padding: 10px;
  }
  
  .one-article-content-upper-abzac {
    padding: 10px;
    font-size: 14px;  
  }
  
  .one-article-header-lower {
  
    font-size: 12px; 
    width: 90%; 
    border-bottom: 3px solid black;

    justify-content: space-around;
  
  }
  
  .one-article-header-hashtegs {
    margin-left: 5px;
  }

}


.noselect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}