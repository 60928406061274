body {
  margin: 0;
  padding: 0;
  background: linear-gradient(0deg, rgb(0, 24, 37), rgb(0, 0, 0));
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: round;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar-button {
    background-image:url('');
    background-repeat:no-repeat;
    width:5px;
    height:0px
    }
    
    ::-webkit-scrollbar-track {
    background-color:#ecedee
    }
    
    ::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color:#6dc0c8;
    }
    
    ::-webkit-scrollbar-thumb:hover{
    background-color:#56999f;
    }
    
    ::-webkit-resizer{
    background-image:url('');
    background-repeat:no-repeat;
    width:4px;
    height:0px
    }
    
    ::-webkit-scrollbar{
    width: 4px;
    }
  
  @font-face {
    font-family: 'Ubuntu';
    font-weight: 400;
    src: url(/static/media/MyriadPro-Regular.4dc5956a.otf);    
  }

  @font-face {
   font-family: 'Myriad';
   font-weight: 200;
   src: url(/static/media/MyriadPro-Cond.e2a5e608.otf);    
 }

  @font-face {
   font-family: 'Italy-font';
   font-weight: 400;
   src: url(/static/media/ItalyTest.55f8cf24.otf);    
 }
  

a {
    color: white;
}

body {
    background : rgb(250, 185, 101);
    height: 100%;

    font-family: 'Ubuntu';

}

html {
    height: 100%;
}

#root {
    height: 100%;
}
















.GlobalDiv {
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    background: rgb(204, 125, 22);

    background-image: url(/static/media/main.061bcba8.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    padding-top: 30px;


}

.MainDiv {
   min-width: 1000px;
   max-width: 1000px;
   min-height: 300px;
   background: #56999f;

   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
   
   box-shadow: 5px 20px 30px rgba(0, 0, 0, .6);

   margin-right: 30px;
   margin-left: 30px;
}

.Header {
   width: 100%;
   height: 150px;

   background: rgb(212, 202, 113);

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   background-image: url(/static/media/header.fc8226d7.png);
   background-repeat: no-repeat;
   background-size: cover;

   position: relative;

}

.Header-upper-text {
   width: 700px;
   height: 65px; 

   font-family: 'Italy-font';

   color: rgb(139, 17, 17);

   font-size: 55px;
   font-weight: bold;
   text-align: center;

}

a {
   -webkit-text-decoration-line: none;
           text-decoration-line: none;
}

.Header-lower-text {
   width: 600px;
   height: 30px;

   color: rgb(112, 5, 5);

   font-family: 'Italy-font';
   
   font-size: 32px;
   font-weight: bold;
   text-align: center;

   transition-property: 'font-size';
   transition-duration: 0.5s;

   cursor: pointer;
   -webkit-text-decoration-line: none;
           text-decoration-line: none;
   
}

.Header-lower-text:hover {
   font-size: 34px;
}

.Header-adult {
   position: absolute;
   top: 10px;
   left: 950px;
   width: 40px;
   height: 40px;
   border-radius: 20px;

   border: 1px solid rgb(0, 0, 0);

   line-height: 43px;
   text-align: center;

   color: rgb(0, 0, 0);

   opacity: 1;

}

.Header-adult:hover {
   opacity: 1;
}


.Menu-container {

   width: 100%;
   height: 50px;    

   background: rgb(1, 56, 73); 

}

.Content {

   width: 100%;
   min-height: 450px;
   
   background: linear-gradient(rgb(182, 159, 95), rgb(214, 186, 94));

}

.Footer {

   width: 100%;
   min-height: 100px;

   position: relative;
   
   background: rgb(214, 186, 94);

}

.Footer-img {

   width: 1050px;
   height: 112px;

   position: absolute;
   top: 0px;
   left: -25px;

   background: none;

   background-image: url(/static/media/footer.c5b08537.png);
   background-repeat: no-repeat;


   padding-top: 35px;

   text-align: center;
   line-height: 24px;

   color: rgb(56, 47, 18);
   

}

.SideBar {

   /*position: fixed;
   top: 50px;
   left: calc( 50vw + 500px + 50px );*/


   width: 200px;
   min-width: 200px;
   background: rgb(1, 56, 73); 

   border-radius: 10px;
   padding: 20px;
   padding-bottom: 0px;

   display: flex;
   flex-direction: column;

   visibility: visible;

}

.SideBar h1 {
   width: 100%;

   color: white;
   text-align: center;

   font-family: 'Italy-font';
   font-weight: 200;
   font-size: 23px;

   line-height: 25px;

   border-bottom: 1px solid white;
}

.SideBar span {
   color: white;
   margin-top: 10px;

   font-size: 20px;

   font-family: 'Myriad';
   text-align: center;
}

.SideBar a {
   text-decoration: none;
}

.SideBar p {
   width: 100%;
   height: 100px;

   margin-top: 15px;

   border: 1px solid white;

   background: linear-gradient(rgb(143, 1, 1), rgb(61, 1, 1));

   color: white;
   font-size: 20px;

   text-align: center;
   line-height: 105px;

}

.SideBar img {
   width: 100%;
   height: auto;
   margin-bottom: 20px;
}

.SideBar-hashtags {
   padding-top: 20px;
   padding-bottom: 20px;
   display: flex;
   flex-direction: row;

   align-items: flex-end;
   justify-content: center;

   flex-wrap: wrap;

}

.SideBar-hashtags span {
   margin-left: 5px;
   margin-right: 5px;
}

.Menu-div {

   width: 100%;
   height: 50px;    

   background: rgb(1, 56, 73); 

   display: flex;
   flex-direction: row;
   justify-content: space-around;

}

.Menu-div-first-level-menu-item {

   font-family: 'Italy-font';
   height: 100%;

   color: white;

   font-size: 25px;
   font-weight: 300;

   -webkit-animation-name: MenuAnimation;

           animation-name: MenuAnimation;
   -webkit-animation-duration: 1s;
           animation-duration: 1s; 
   -webkit-animation-iteration-count: 1; 
           animation-iteration-count: 1;
   -webkit-animation-direction: normal;
           animation-direction: normal;

   line-height: 50px; 
   opacity: 1;

}

.Menu-div-first-level-menu-item a {
   text-decoration: none;
}

.underline a {
   text-decoration: underline;
}

@-webkit-keyframes MenuAnimation {
   from { line-height: 0px; opacity: 0; }
   to { line-height: 50px; opacity: 1; }
}

@keyframes MenuAnimation {
   from { line-height: 0px; opacity: 0; }
   to { line-height: 50px; opacity: 1; }
}



















.menu-block {

    width: 25%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    

    color: white; 
    
    transition-property: background-color;
    transition-duration: 0.5s;

    background-color: black;
}

.menu-block:hover {
    transition-property: background-color;
    transition-duration: 0.5s;

    background-color: rgb(16, 0, 59);  
}

 
@-webkit-keyframes menuKF {
    from { height: 0px}
    to { height: 70px}
}

 
@keyframes menuKF {
    from { height: 0px}
    to { height: 70px}
}


 @media screen and (max-width:1200px) {

   .SideBar {
      visibility: hidden;
      min-width: 0px;
      width: 0px;
      padding: 0px;
   }

   .GlobalDiv {      
   }

 }



 @media screen and (min-width:1450px) {
    .div2-inside-wide {
        font-size: 16px;
     }
 }


 .div15-call {

    height: 60px;

    padding: 20px;
    border-radius: 20px;

    /*-webkit-animation: menuKF 0.5s forwards;*/
    
    -webkit-animation-name: pulse;
    
            animation-name: pulse;
    -webkit-animation-duration: 2s;
            animation-duration: 2s; 

    -webkit-animation-iteration-count: infinite; 

            animation-iteration-count: infinite;

 }


@-webkit-keyframes pulse{
0%{font-size: 15px; background-color: blue;}
50%{font-size: 16px; background-color: rgb(3, 3, 122);}
70%{font-size: 18px; background-color: rgb(110, 2, 105);}
100%{font-size: 15px; background-color: blue;}
}


@keyframes pulse{
0%{font-size: 15px; background-color: blue;}
50%{font-size: 16px; background-color: rgb(3, 3, 122);}
70%{font-size: 18px; background-color: rgb(110, 2, 105);}
100%{font-size: 15px; background-color: blue;}
}



.tooltip {
	cursor: help;
}

.tooltip::after {
	background: rgba(0, 0, 0, 0.8);
	border-radius: 8px 8px 8px 8px;
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
	color: #FFF;
   content: attr(data-tooltip); /* Главная часть кода, определяющая содержимое всплывающей подсказки */
   margin-top: -24px;
	opacity: 0; /* Наш элемент прозрачен... */
	padding: 3px 7px;
	position: absolute;
   visibility: hidden; /* ...и скрыт. */
   
   margin-left: -540px;
   width: 500px;
			
	transition: all 0.4s ease-in-out; /* Добавить плавности по вкусу */
}
		
.tooltip:hover::after {
	opacity: 1; /* Показываем его */
	visibility: visible;
}



/* МОБИЛЬНАЯ ВЕРСТКА */

@media screen and (max-width:900px) {

   .GlobalDiv {
      justify-content: center;
   }


   .MainDiv {
      min-width: 90vw;
      max-width: 90vw;
      min-height: 300px;
      
      margin-right: 10px;
      margin-left: 10px;
   }
   
   .Header {
      height: 100px;   
   }
   
   .Header-upper-text {
      width: 100%;
      height: 35px; 
      font-size: 35px;   
   }
   
   .Header-lower-text {
      width: 100%;
      height: 20px;
      
      font-size: 20px;      
   }
   
   .Header-lower-text:hover {
      font-size: 22px;
   }
   
   .Header-adult {
   
      opacity: 0;
   
   }

   .Menu-div {

      height: 30px;    
   
   }

   .Menu-div-first-level-menu-item {
      font-size: 10px;  
      line-height: 30px; 
   }

   .Footer {

      min-height: 32px;
   
   }
   
   .Footer-img {
   
      width: 94vw;
      height: 10vw;
   
      position: absolute;
      top: 0px;
      left: -2vw;
   
      background: none;
   
      background-image: url(/static/media/footer.c5b08537.png);
      background-size: cover;
      background-position: center;
   
      text-align: center;
      line-height: 3vw;

      font-size: 2.5vw;
   
      color: rgb(56, 47, 18);
      
   
   }

 }
.content-main {
    height: 400px;
}

/* MAIN */

.main-page {
    padding: 20px;
    flex-wrap: wrap;
}

.article-container {
    width: 300px;
    height: 260px;

    position: relative;

    background: rgb(1, 56, 73); 
    color: white;

    transition: all 0.3s ease-in-out; 

    box-shadow: 2px 5px 7px rgba(0, 0, 0, .6);

    margin-bottom: 30px;
}

.article-container img {
    width: 300px;
    height: 170px;
}

.article-container:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.article-bottom {
    padding: 4px;
    padding-left: 10px; 

    font-family: 'Ubuntu';
    font-size: 19px;

    color: white;

    text-decoration: none;
}

.article-bottom h1 {
    font-size: 12px;
    font-weight: 100;
    max-height: 45px;
    overflow: hidden; /* Обрезаем все, что не помещается в область */

    text-overflow: ellipsis;
}

.article-hashtags {

    width: 300px;
    height: 30px;

    position: absolute;

    left: 0px;
    top: 135px;

    padding-left: 10px; 

}


.hashtag-upper {

    display: inline-block;

    height: 14px;
    padding: 4px; 
    padding-left: 20px;  
    padding-right: 20px;

    background: rgb(1, 56, 73, 0.8); 
    border: 3px rgb(6, 98, 126) solid;

    border-radius: 8px;

    color: white;

    line-height: 18px;
    text-align: center;

    margin-right: 5px;

}



/* NEWS */

.one-new-div {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgb(241, 196, 144);
    padding-top: 15px;
}

.one-new-div-picture {
    width: 90px;
    height: 50px;

    border: 1px solid white;

    background: blue;
}

.one-new-div-titles {
    width: 100%;
    height: 100%
}

.one-new-div-title {
    font-size: 20px;
    color: black;
}

.one-new-div-title:hover {
    font-size: 20px;
    color: black;

    font-weight: bold;
    cursor: pointer;

}

.one-new-div-date {
   width: 100%;
   text-align: end;
   color: rgb(95, 63, 26);
   padding-right: 30px;
}





/* NO PAGE */

.nopage img {
    width: auto;
    height: 90px;
 }

.nopage p {
   color: rgb(100, 16, 16);
   margin-left: 20px;
   font-size: 40px;
   font-family: 'Italy-font';
}

.flex-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.flex-center-between {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.one-article {
  width: 1000px;
  min-height: 500px;
}

.one-article-header {
  width: 1000px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.one-article-header-background {

  margin-top: 25px;

  width: 800px;
  height: 450px;
}


.one-article-header-title {
   color: black;
   font-size: 40px;
   font-weight: bold;

   width: 1000px;
   padding-bottom: 5px;
   padding-top: 30px;

   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.hashtag-upper-article {

    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;

}

.one-article-content-container {
  padding: 30px;
  padding-left: 100px;
  padding-right: 100px;
}

.one-article-content-upper-abzac {
  padding: 30px;
  padding-bottom: 0px;

  padding-left: 100px;
  padding-right: 100px;

  color: black;
  font-size: 20px;
  font-weight: bold;

  width: 80%;

}

.one-article-header-lower {

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 80%;

  color: rgb(53, 53, 53);


  border-bottom: 7px solid black;

}

.one-article-header-hashtegs {
  margin-left: 40px;
  margin-bottom: 30px;

  padding-left: 60px;
  padding-right: 100px;

}


/* МОБИЛЬНАЯ ВЕРСТКА */

@media screen and (max-width:900px) {

  .one-article {
    width: 90vw;
    min-height: 500px;
  }
  
  .one-article-header {
    width: 90vw;
  }
  
  .one-article-header-background {
  
    margin-top: 15px;
  
    width: 70vw;
    height: 190px;
  }
  
  
  .one-article-header-title {
     font-size: 20px;
  
     width: 90vw;
     padding-bottom: 15px;
     padding-top: 20px;
  }
  
  .hashtag-upper-article {
  
      margin-left: 3px;
      margin-right: 2px;
  
  }
  
  .one-article-content-container {
    padding: 10px;
  }
  
  .one-article-content-upper-abzac {
    padding: 10px;
    font-size: 14px;  
  }
  
  .one-article-header-lower {
  
    font-size: 12px; 
    width: 90%; 
    border-bottom: 3px solid black;

    justify-content: space-around;
  
  }
  
  .one-article-header-hashtegs {
    margin-left: 5px;
  }

}


.noselect {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
