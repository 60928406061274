.content-main {
    height: 400px;
}

/* MAIN */

.main-page {
    padding: 20px;
    flex-wrap: wrap;
}

.article-container {
    width: 300px;
    height: 260px;

    position: relative;

    background: rgb(1, 56, 73); 
    color: white;

    transition: all 0.3s ease-in-out; 

    box-shadow: 2px 5px 7px rgba(0, 0, 0, .6);

    margin-bottom: 30px;
}

.article-container img {
    width: 300px;
    height: 170px;
}

.article-container:hover {
    transform: scale(1.1);
}

.article-bottom {
    padding: 4px;
    padding-left: 10px; 

    font-family: 'Ubuntu';
    font-size: 19px;

    color: white;

    text-decoration: none;
}

.article-bottom h1 {
    font-size: 12px;
    font-weight: 100;
    max-height: 45px;
    overflow: hidden; /* Обрезаем все, что не помещается в область */

    text-overflow: ellipsis;
}

.article-hashtags {

    width: 300px;
    height: 30px;

    position: absolute;

    left: 0px;
    top: 135px;

    padding-left: 10px; 

}


.hashtag-upper {

    display: inline-block;

    height: 14px;
    padding: 4px; 
    padding-left: 20px;  
    padding-right: 20px;

    background: rgb(1, 56, 73, 0.8); 
    border: 3px rgb(6, 98, 126) solid;

    border-radius: 8px;

    color: white;

    line-height: 18px;
    text-align: center;

    margin-right: 5px;

}



/* NEWS */

.one-new-div {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgb(241, 196, 144);
    padding-top: 15px;
}

.one-new-div-picture {
    width: 90px;
    height: 50px;

    border: 1px solid white;

    background: blue;
}

.one-new-div-titles {
    width: 100%;
    height: 100%
}

.one-new-div-title {
    font-size: 20px;
    color: black;
}

.one-new-div-title:hover {
    font-size: 20px;
    color: black;

    font-weight: bold;
    cursor: pointer;

}

.one-new-div-date {
   width: 100%;
   text-align: end;
   color: rgb(95, 63, 26);
   padding-right: 30px;
}





/* NO PAGE */

.nopage img {
    width: auto;
    height: 90px;
 }

.nopage p {
   color: rgb(100, 16, 16);
   margin-left: 20px;
   font-size: 40px;
   font-family: 'Italy-font';
}

.flex-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.flex-center-between {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}